import React, { memo } from 'react'
import propTypes from 'prop-types'
import { useSelector } from 'react-redux'
import './styles.scss'
import {
  removeEmptyHtmlTags,
  relativeBlockImageHeight,
  relativeFontSize,
} from '../../../utils'

const PageThumbnailContents = ({ items, type, parent, pageType }) => {
  const images = useSelector((state) => state.images.images)
  const blocks = useSelector((state) => state.blocks)
  const blockImages = useSelector((state) => state.blockImages.images)
  const blockImagesArray = Object.values(blockImages) || undefined
  const questions = useSelector((state) => state.questions)
  const answers = useSelector((state) => state.answers)

  const getHeight = (item) => {
    if (item.w === 0.3 && item.h === 0.3) return 'auto'
    return item.h * parent.height
  }

  const getThumbBlocks = (blockId) => {
    const theBlock = blocks[blockId]
    const thumbBlockText = removeEmptyHtmlTags(theBlock.html)
    const blockImagesForId = blockImagesArray
      .filter((bi) => bi.blockId === blockId)
      .sort((a, b) => a.order - b.order)
    const thumbBlockImages = blockImagesForId.map((image) => (
      <img
        key={image.id}
        src={image.filename}
        alt={image.alt ? image.alt : ''}
        className="thumb-block-image"
        style={{
          height: relativeBlockImageHeight(
            theBlock.scale,
            parent.width,
            parent.height,
            pageType
          ),
        }}
      />
    ))
    return thumbBlockImages ? (
      <div className="page-thumb-block">
        <div
          className="page-thumb-block-image-row"
          style={{
            justifyContent: 'space-between',
            padding: '0.5em 0.5em 0',
            backgroundColor: theBlock.bgColor,
          }}
        >
          {thumbBlockImages}
        </div>
        <div
          className="page-thumb-block-text-row"
          style={{
            width: '100%',
            fontSize: relativeFontSize(
              theBlock.scale,
              parent.width,
              parent.height,
              pageType
            ),
            padding: '0.5em',
            backgroundColor: theBlock.bgColor,
          }}
          dangerouslySetInnerHTML={{ __html: thumbBlockText }}
        ></div>
      </div>
    ) : (
      <div
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: 'transparent',
          border: '1px solid gray',
        }}
      />
    )
  }

  const getThumbQuestions = (questionId) => {
    const theQuestion = questions[questionId]
    const theAnswers = Object.values(answers).filter(
      (a) => a.questionId === questionId
    )
    const thumbQuestion = removeEmptyHtmlTags(theQuestion.html)
    return (
      <div
        className="page-thumb-question-wrapper"
        style={{ backgroundColor: theQuestion.bgColor }}
      >
        <div
          className="page-thumb-question"
          style={{
            width: '100%',
            fontSize: relativeFontSize(
              theQuestion.scale,
              parent.width,
              parent.height,
              pageType
            ),
            padding: '0.5em',
          }}
          dangerouslySetInnerHTML={{ __html: thumbQuestion }}
        ></div>
        {theAnswers.map((answer) => (
          <div
            key={answer.id}
            className="page-thumb-answer"
            style={{
              width: '100%',
              fontSize: relativeFontSize(
                theQuestion.scale * 0.8,
                parent.width,
                parent.height,
                pageType
              ),
              backgroundColor: answer.bgColor,
              padding: '0.5em',
            }}
            dangerouslySetInnerHTML={{ __html: answer.html }}
          ></div>
        ))}
      </div>
    )
  }

  const thumbnailItems =
    parent &&
    items &&
    items.length > 0 &&
    items.map((item) => {
      if (item) {
        return (
          <div
            key={item.id}
            alt=""
            style={{
              width: type === 'block' ? 'fit-content' : item.w * parent.width,
              height: type === 'block' ? 'fit-content' : getHeight(item),
              position: 'absolute',
              top: item.y * parent.height,
              left: item.x * parent.width,
              zIndex: item.z,
              objectFit: 'contain',
            }}
          >
            {type === 'block' &&
              item?.id &&
              blockImagesArray &&
              getThumbBlocks(item.id)}
            {type === 'question' && item?.id && getThumbQuestions(item.id)}
            {type === 'image' && item.draggable ? (
              <img
                src={item.filename}
                alt={item.alt ? item.alt : ''}
                width={item.w ? '100%' : item.width}
                height={item.h ? '100%' : item.height}
              />
            ) : (
              <>
                {item && type === 'cell' && images[item.imageId] ? (
                  <div
                    style={{ position: 'relative', width: '100%', height: '100%' }}
                  >
                    <img
                      src={images[item.imageId].filename}
                      alt={images[item.imageId].alt ? images[item.imageId].alt : ''}
                      width="90%"
                      height="90%"
                      className="thumbnail-image-content"
                    />
                  </div>
                ) : (
                  <img
                    src={item.filename}
                    alt={item.alt ? item.alt : ''}
                    width="100%"
                    height="100%"
                    className="thumbnail-image-content"
                  />
                )}
              </>
            )}
            {type !== 'image' &&
              type !== 'cell' &&
              type !== 'block' &&
              type !== 'question' && (
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: item.bgColor,
                    border: '1px solid gray',
                  }}
                />
              )}
          </div>
        )
      }
    })
  return <>{thumbnailItems}</>
}

PageThumbnailContents.propTypes = {
  items: propTypes.instanceOf(Array).isRequired,
  type: propTypes.string.isRequired,
  parent: propTypes.instanceOf(Object).isRequired,
  pageType: propTypes.number,
}

export default memo(PageThumbnailContents)
