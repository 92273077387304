import React, { memo, useEffect, useState } from 'react'
import propTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '../../../Button'
import { convertToObject } from '../../../../utils'
import '../styles.scss'

const PreviewCell = ({ parent, cell, pageFromApi }) => {
  const [currentlyPlayingSound, setCurrentlyPlayingSound] = useState(false)
  const soundsFromState = useSelector((state) => state.sounds)
  const imagesFromState = useSelector((state) => state.images.images)
  const [images, setImages] = useState(null)
  const [sounds, setSounds] = useState(null)
  const audioRef = React.useRef()

  useEffect(() => {
    if (imagesFromState && imagesFromState.length > 0) {
      setSounds(imagesFromState)
    } else if (pageFromApi.images) {
      const imagesWithKeys = convertToObject(pageFromApi.images)
      setImages(imagesWithKeys)
    }
  }, [cell, pageFromApi, soundsFromState])

  useEffect(() => {
    if (soundsFromState && soundsFromState.length > 0) {
      setSounds(soundsFromState)
    } else if (pageFromApi.sounds) {
      const soundsWithKeys = convertToObject(pageFromApi.sounds)
      setSounds(soundsWithKeys)
    }
  }, [cell, pageFromApi, soundsFromState])

  const togglePlaySound = () => {
    if (!currentlyPlayingSound) {
      audioRef.current.play()
      setCurrentlyPlayingSound(true)
    } else {
      audioRef.current.pause()
      setCurrentlyPlayingSound(false)
    }
  }

  const getWidth = () => {
    return cell.w * parent.width
  }

  const getHeight = () => {
    return cell.h * parent.height
  }

  return (
    <>
      {cell ? (
        <div
          style={{
            width: parent && getWidth(),
            height: parent && getHeight(),
          }}
          className="preview-image-container"
        >
          {cell && cell.soundId && sounds && images[cell.imageId] ? (
            <>
              <Button
                className="preview-cell-audio-button"
                onClick={togglePlaySound}
                style="padding: '2%'"
              >
                <div className="cell-image-caption">
                  {images[cell.imageId].caption && (
                    <p>{images[cell.imageId].caption}</p>
                  )}
                </div>
                <img
                  src={images[cell.imageId].filename}
                  alt={images[cell.imageId].alt ? images[cell.imageId].alt : ''}
                  height="85%"
                  width="100%"
                  className="uploaded-image-content preview-image"
                />
                <audio
                  ref={audioRef}
                  src={sounds[cell.soundId].filename}
                  onEnded={() => setCurrentlyPlayingSound(false)}
                />
                <FontAwesomeIcon
                  icon="volume-up"
                  className="audio-cell-icon"
                  size="1x"
                />
              </Button>
            </>
          ) : (
            <>
              {images && images[cell.imageId] && (
                <>
                  <div className="cell-image-caption">
                    {images[cell.imageId].caption && (
                      <p>{images[cell.imageId].caption}</p>
                    )}
                  </div>
                  <img
                    src={images[cell.imageId].filename}
                    alt={images[cell.imageId].alt ? images[cell.imageId].alt : ''}
                    height="85%"
                    width="100%"
                    className="uploaded-image-content preview-image"
                  />
                </>
              )}
            </>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

PreviewCell.propTypes = {
  parent: propTypes.instanceOf(Object).isRequired,
  cell: propTypes.instanceOf(Object).isRequired,
  pageFromApi: propTypes.instanceOf(Object),
}

PreviewCell.defaultProps = {
  pageFromApi: null,
}

export default memo(PreviewCell)
