import React, { memo, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import InstructionsSider from '../components/InstructionsSider'
import LoadingPage from '../components/LoadingPage'
import MainNavigationContainer from './MainNavigationContainer'
import EditPage from '../components/EditPage'
import PagesSider from '../components/PagesSider'
import DeleteItemDialogue from '../components/DeleteItemDialogue'
import ModalContainer from '../components/ModalContainer'
import Templates from '../components/CreateBook/Templates'
import AddImage from '../components/AddImage'
import UploadFile from '../components/UploadFile'
import ShareModal from '../components/ShareBook'
import { addVideo, deleteVideo } from '../store/videos/videoAction'
import { addCell } from '../store/cells/cellAction'
import { createPage } from '../api/books/pages'
import { addPage, deletePage, getNewCurrentPage } from '../store/pages/pageAction'
import { updateBook } from '../store/books/bookAction'
import { addText } from '../store/texts/textAction'
import { addBlock } from '../store/blocks/blockAction'
import { addQuestion } from '../store/questions/questionAction'
import { useDispatch, useSelector } from 'react-redux'
import AddPageView from '../components/AddPageView'
import TopNavigation from '../components/Navigation/TopNavigation'
import {
  getGridImageSizeAndPosition,
  pageLayoutIsHorizontal,
  pageTypeIsFree,
} from '../utils'

const PagesContainer = () => {
  const [openModal, setOpenModal] = useState(false)
  const [openColorModal, setOpenColorModal] = useState(false)
  const [openTemplateModal, setOpenTemplateModal] = useState(false)
  const [openDeletePageModal, setOpenDeletePageModal] = useState(false)
  const [showGrid, setShowGrid] = useState(false)
  const [openUploadModal, setOpenUploadModal] = useState(false)
  const [mediaType, setMediaType] = useState('image')
  const [openShareModal, setOpenShareModal] = useState(false)
  const [deletedPageId, setDeletedPageId] = useState(null)
  const [openCardModal, setOpenCardModal] = useState(false)
  const [source, setSource] = useState(null)
  const [selectedBlockId, setSelectedBlockId] = useState(undefined)

  const setOpenModalAndSelectedBlockId = (modal, block = undefined) => {
    setOpenModal(modal)
    setSelectedBlockId(block)
  }

  const user = useSelector((state) => state.user)

  const navigate = useNavigate()
  const location = useLocation()
  const currentBookId = useSelector((state) => state.books.currentBook)
  const book = useSelector((state) => state.books.books[currentBookId])
  const dispatch = useDispatch()
  const pages = useSelector((state) => state.pages.pages)
  const currentPageId = useSelector((state) => state.pages.currentPage)
  const currentPage = useSelector((state) => state.pages.pages[currentPageId])
  const loading = useSelector((state) => state.root.loading)

  useEffect(() => {
    if (!user || (user && user.error)) {
      navigate('/')
    }
  })

  useEffect(() => {
    if (book && book.pages && book.pages.length > 0 && !currentPage && pages) {
      const currentBookPages = Object.values(pages)
        .filter((p) => book.pages.includes(p.id))
        .sort((a, b) => a.order - b.order)
      const firstPage = currentBookPages[0]
      if (firstPage) {
        dispatch(getNewCurrentPage(firstPage.id))
      }
    }
  }, [currentBookId, currentPage, pages, book])

  useEffect(() => {
    if (
      book &&
      location &&
      location.state &&
      location.state.showTemplates === true
    ) {
      setOpenTemplateModal(true)
      navigate({ state: { showTemplates: false } })
    }
  }, [book, location])

  const addNewTextArea = (page, x, y, width, height, resizable, draggable) => {
    const newTextArea = {
      pageId: page ? page.id : currentPage.id,
      bgColor: 'transparent',
      x: x ? x : 0,
      y: y ? y : 0,
      w: width ? width : '30%',
      h: height ? height : '10%',
      z: 100,
      resizable,
      draggable,
      userId: user.id,
    }
    dispatch(addText(newTextArea))
    if (currentPage && pageTypeIsFree(currentPage.type)) {
      const updatedBook = {
        ...book,
        updated: Date.now(),
      }
      dispatch(updateBook(updatedBook))
    }
  }

  const addNewBlock = (page, x, y, width, height, resizable, draggable) => {
    const newBlock = {
      pageId: page ? page.id : currentPage.id,
      bgColor: 'transparent',
      x: x ? x : 0,
      y: y ? y : 0,
      w: width ? width : '40%',
      h: height ? height : '13%',
      z: 100,
      resizable,
      draggable,
      userId: user.id,
    }
    dispatch(addBlock(newBlock))
    if (currentPage && pageTypeIsFree(currentPage.type)) {
      const updatedBook = {
        ...book,
        updated: Date.now(),
      }
      dispatch(updateBook(updatedBook))
    }
  }

  const addNewQuestion = (page, x, y, width, height, resizable, draggable) => {
    const newQuestion = {
      pageId: page ? page.id : currentPage.id,
      bgColor: 'transparent',
      x: x ? x : 0,
      y: y ? y : 0,
      w: width ? width : '40%',
      h: height ? height : '13%',
      z: 100,
      resizable,
      draggable,
      userId: user.id,
    }
    dispatch(addQuestion(newQuestion))
    if (currentPage && pageTypeIsFree(currentPage.type)) {
      const updatedBook = {
        ...book,
        updated: Date.now(),
      }
      dispatch(updateBook(updatedBook))
    }
  }

  const addPositionedTextArea = (page, template) => {
    switch (template) {
      case 0:
        addNewTextArea(page, 0.52, 0.08, 0.425, 0.85, 0, 0)
        break
      case 1:
        addNewTextArea(page, 0.05, 0.08, 0.425, 0.85, 0, 0)
        break
      case 2:
        addNewTextArea(page, 0.18, 0.73, 0.64, 0.25, 0, 0)
        break
      default:
        break
    }
  }

  const addCells = (page) => {
    let maxIndex
    if (page && page.type === 6) {
      maxIndex = 3
    } else if (page && page.type === 7) {
      maxIndex = 5
    } else if (page && page.type === 8) {
      maxIndex = 11
    }
    // DEMO: Määrittää kuinka monta ruudukkoa ruudukkopohjassa sivutyypin mukaan
    // else if (page && page.type === 9) {
    //   maxIndex = 23
    // }
    for (let i = 0; i <= maxIndex; i++) {
      const { x, y, w, h } = getGridImageSizeAndPosition(page.type, i)
      dispatch(
        addCell({
          pageId: page.id,
          imageId: null,
          soundId: null,
          position: i,
          x: x,
          y: y,
          w: w,
          h: h,
        })
      )
    }
  }

  const removeVideo = async (id, newVideo) => {
    dispatch(deleteVideo(id))
    if (newVideo) {
      dispatch(addVideo(newVideo))
    }
  }

  const handleSubmit = (chosenTemplate) => {
    let currentBookPages = []
    if (book && book.pages && book.pages.length > 0 && pages) {
      currentBookPages = Object.values(pages)
        .filter((p) => book.pages.includes(p.id))
        .sort((a, b) => a.order - b.order)
    }
    const order =
      currentBookPages.length > 0
        ? currentBookPages[currentBookPages.length - 1].order + 1
        : 0
    const template = parseInt(chosenTemplate)
    createPage(book.id, order, template, 'FFFFFF').then((res) => {
      const colorCodedPage = { ...res.data, bgColor: '#FFFFFF' }
      dispatch(addPage(colorCodedPage))
        .then(() => {
          addPositionedTextArea(res.data, template)
          dispatch(getNewCurrentPage(res.data.id))
          if (template > 5 && template < 9) {
            addCells(res.data, template)
          }
        })
        .then(() => {
          const copyOfMaterialPages = book.pages
          const updatedBook = {
            ...book,
            updated: Date.now(),
            pages: copyOfMaterialPages
              ? copyOfMaterialPages.concat(res.data.id)
              : [res.data.id],
          }
          dispatch(updateBook(updatedBook))
        })
      setOpenTemplateModal(false)
    })
  }

  const openModalAndSetType = (type) => {
    setOpenUploadModal(true)
    setMediaType(type)
  }

  const setNewCurrentPage = () => {
    const sortedBookPages = Object.values(pages)
      .filter((p) => book.pages.includes(p.id))
      .sort((p1, p2) => p1.order - p2.order)
    let currentPageIndex
    sortedBookPages.find((p, index) => {
      if (currentPage.id === p.id) {
        currentPageIndex = index
      }
    })
    if (currentPage) {
      if (currentPageIndex === 0 && sortedBookPages.length >= 2) {
        dispatch(getNewCurrentPage(sortedBookPages[1].id))
      } else if (currentPageIndex === 0 && sortedBookPages.length === 1) {
        dispatch(getNewCurrentPage(null))
      } else if (currentPageIndex === sortedBookPages.length - 1) {
        dispatch(getNewCurrentPage(sortedBookPages[sortedBookPages.length - 2].id))
      } else {
        dispatch(getNewCurrentPage(sortedBookPages[currentPageIndex - 1].id))
      }
    }
    setDeletedPageId(null)
  }

  const deleteThisPage = (pageId) => {
    setDeletedPageId(pageId)
    try {
      if (pageId === currentPage.id) {
        setNewCurrentPage()
      }
      dispatch(deletePage(pageId))
      const updatedMaterial = {
        ...book,
        updated: Date.now(),
        pages: book.pages.filter((p) => p !== pageId),
      }
      dispatch(updateBook(updatedMaterial))
      setOpenDeletePageModal(false)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }

  const closeUploadModalAndCancelRequest = () => {
    if (source) {
      source.cancel('Lataus peruutettu')
      setSource(null)
    }
    setOpenUploadModal(false)
  }
  return (
    <div className="page-container">
      {!loading && <TopNavigation />}
      {book && currentPage && (
        <div
          className={
            pageLayoutIsHorizontal(currentPage?.type)
              ? 'edit-view'
              : 'edit-view edit-view-vertical-layout'
          } // DEMO: Muokkausnäkymän CSS korkeus sivutyypin mukaan
        >
          <InstructionsSider
            setOpenModal={setOpenModalAndSelectedBlockId}
            addNewTextArea={addNewTextArea}
            setOpenColorModal={setOpenColorModal}
            openTemplateModal={openTemplateModal}
            removeVideo={removeVideo}
            showGrid={showGrid}
            setShowGrid={setShowGrid}
            setOpenUploadModal={openModalAndSetType}
            addNewBlock={addNewBlock}
            addNewQuestion={addNewQuestion}
          />
          <EditPage
            setModalIsOpen={setOpenModal}
            openColorModal={openColorModal}
            setOpenColorModal={setOpenColorModal}
            showGrid={showGrid}
            removeVideo={removeVideo}
            setOpenShareModal={setOpenShareModal}
            addNewTextArea={addNewTextArea}
            addNewQuestion={addNewQuestion}
            setOpenModal={setOpenModalAndSelectedBlockId}
          />
          <PagesSider
            setOpenTemplateModal={setOpenTemplateModal}
            openDeletePageModal={openDeletePageModal}
            setOpenDeletePageModal={setOpenDeletePageModal}
            setDeletedPageId={setDeletedPageId}
          />
        </div>
      )}
      {(!currentPage || !book) && loading && (
        <>
          <MainNavigationContainer />
          <LoadingPage message="Ladataan kirjaa" />
        </>
      )}
      {!currentPage && !loading && (
        <div className="edit-view">
          <div className="sider-container sider-instructions-container" tabIndex={0}>
            <div className="instructions-sider-content">
              <div className="instructions-sider-header">
                <h2 className="instructions-sider-title">Ohje</h2>
              </div>
              <div style={{ marginTop: '2em' }} className="instructions-sider-text">
                <p>Kirjassa ei ole yhtään sivua.</p>
                <p>Voit lisätä uuden sivun painamalla Lisää sivu-napista.</p>
              </div>
            </div>
          </div>
          <AddPageView setOpenTemplateModal={setOpenTemplateModal} />
          <PagesSider
            setOpenTemplateModal={setOpenTemplateModal}
            openDeletePageModal={openDeletePageModal}
            setOpenDeletePageModal={setOpenDeletePageModal}
            setDeletedPageId={setDeletedPageId}
          />
        </div>
      )}
      <>
        {openTemplateModal && (
          <ModalContainer
            modalIsOpen={openTemplateModal}
            closeModal={() => setOpenTemplateModal(false)}
            label="Valitse sivupohja."
          >
            <Templates
              handleSubmit={handleSubmit}
              setOpenTemplateModal={setOpenTemplateModal}
            />
          </ModalContainer>
        )}
        {openUploadModal && (
          <ModalContainer
            modalIsOpen={openUploadModal}
            closeModal={closeUploadModalAndCancelRequest}
            label="Lataa tiedostoja."
            info
          >
            <UploadFile
              closeModal={closeUploadModalAndCancelRequest}
              type={mediaType}
              text={
                mediaType === 'audio'
                  ? [
                      'Sallitut tiedostomuodot: MP3, WAV, M4A, AAC, OGG, 3GP, AMR, MOV, MP4, M4V.',
                    ]
                  : [
                      'Videotiedosto ei saa olla tiedostokooltaan liian suuri. Maksimikoko on 50Mt.',
                      'Videon maksimipituus on 1 minuutti. Pidemmät videot lyhennetään tähän pituuteen.',
                      'Sallitut tiedostomuodot: MP4, M4V, AVI, MPG, MPEG, WEBM, MOV, WMV, FLV.',
                    ]
              }
              setSource={setSource}
            />
          </ModalContainer>
        )}
        {openModal && (
          <ModalContainer
            modalIsOpen={openModal}
            closeModal={() => setOpenModalAndSelectedBlockId(false)}
            label="Valitse kuva tai video."
            styleName="choose-image"
          >
            <AddImage
              closeModal={() => setOpenModalAndSelectedBlockId(false)}
              openCardModal={openCardModal}
              setOpenCardModal={setOpenCardModal}
              selectedBlockId={selectedBlockId}
            />
          </ModalContainer>
        )}
        {openDeletePageModal && (
          <DeleteItemDialogue
            openDeleteContentModal={openDeletePageModal}
            setOpenDeleteContentModal={setOpenDeletePageModal}
            deleteItem={deleteThisPage}
            itemId={deletedPageId}
            textContent={['Haluatko varmasti poistaa sivun?', 'Kyllä, poista']}
          />
        )}
        {openShareModal && (
          <ShareModal
            modalIsOpen={openShareModal}
            closeModal={() => setOpenShareModal(false)}
          />
        )}
      </>
    </div>
  )
}

export default memo(PagesContainer)
