import React, { useEffect, useState } from 'react'
import { Navigate, Routes, Route, useLocation } from 'react-router-dom'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { library } from '@fortawesome/fontawesome-svg-core'
import { useDispatch, useSelector } from 'react-redux'
import {
  faAlignLeft,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowsAlt,
  faBorderAll,
  faColumns,
  faExpandAlt,
  faInfoCircle,
  faMicrophone,
  faMinus,
  faPalette,
  faPlayCircle,
  faPlus,
  faQuestion,
  faSignOutAlt,
  faSortAlphaDown,
  faSortAlphaDownAlt,
  faSpinner,
  faTimes,
  faVolumeUp,
  faReply,
  faFillDrip,
  faFont,
} from '@fortawesome/free-solid-svg-icons'
import {
  faClock,
  faCopy,
  faEdit,
  faEye,
  faEyeSlash,
  faFileImage,
  faFileVideo,
  faImage,
  faImages,
  faLifeRing,
  faObjectUngroup,
  faPauseCircle,
  faPlusSquare,
  faQuestionCircle,
  faSave,
  faShareSquare,
  faTrashAlt,
  faUserCircle,
  faTimesCircle,
  faThumbsUp,
  faThumbsDown,
} from '@fortawesome/free-regular-svg-icons'
import { isIE } from 'react-device-detect'
import './styles/defaults.scss'
import LoginContainer from './containers/LoginContainer'
import ActivateAccountContainer from './containers/ActivateAccountContainer'
import FeedbackContainer from './containers/FeedbackContainer'
import EditAccountContainer from './containers/EditAccountContainer'
import ModalContainer from './components/ModalContainer'
import PagesContainer from './containers/PagesContainer'
import OwnBooksContainer from './containers/OwnBooksContainer'
import PrivateRoute from './PrivateRoute'
import PrivateOpikeRoute from './PrivateOpikeRoute'
import PublicBooksContainer from './containers/PublicBooksContainer'
import ReadStoryContainer from './containers/ReadStoryContainer'
import TestPapuriContainer from './containers/TestPapuriContainer'
import InfoPage from './components/InfoPage'
import Button from './components/Button'
import { getUser } from './api/users/users'
import { logoutUser } from './store/user/userAction'
import { rootStartUp } from './store/root/rootAction'
import PreviewContainer from './containers/PreviewContainer'
import OpikeContainer from './containers/OpikeContainer'

library.add(
  faAlignLeft,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowsAlt,
  faBorderAll,
  faClock,
  faColumns,
  faCopy,
  faEdit,
  faExpandAlt,
  faEye,
  faEyeSlash,
  faFileImage,
  faFileVideo,
  faImage,
  faImages,
  faInfoCircle,
  faLifeRing,
  faMicrophone,
  faMinus,
  faObjectUngroup,
  faPalette,
  faPauseCircle,
  faPlayCircle,
  faPlus,
  faPlusSquare,
  faQuestion,
  faQuestionCircle,
  faSave,
  faShareSquare,
  faSignOutAlt,
  faSortAlphaDown,
  faSortAlphaDownAlt,
  faSpinner,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faUserCircle,
  faVolumeUp,
  faReply,
  faFillDrip,
  faFont,
  faThumbsUp,
  faThumbsDown
)

const App = () => {
  const { trackPageView } = useMatomo()
  const [openInfoModal, setOpenInfoModal] = useState(false)
  const user = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const location = useLocation()

  useEffect(() => {
    trackPageView()
  }, [location.pathname])

  useEffect(() => {
    try {
      getUser().then((res) => {
        if (res) {
          if (!user) {
            dispatch(rootStartUp())
          }
        } else if (user && !user.anonymous) {
          dispatch(logoutUser())
        }
      })
    } catch {
      dispatch(logoutUser())
    }
  }, [])

  return (
    <>
      <main className="App">
        <div className="main-container">
          <Routes>
            <Route
              path="/aktivointi/:userId/:activationkey"
              element={<ActivateAccountContainer />}
            />
            <Route path="/lue/:bookId/:bookUrl" element={<ReadStoryContainer />} />
            <Route path="/info" element={<InfoPage />} />
            <Route path="/kokeile" element={<TestPapuriContainer />} />
            <Route
              path="/"
              element={
                user && !(user.error || user.anonymous) ? (
                  <OwnBooksContainer />
                ) : (
                  <LoginContainer />
                )
              }
            />
            <Route
              path="/omat-kirjat"
              element={
                <PrivateRoute>
                  <OwnBooksContainer />
                </PrivateRoute>
              }
            />
            <Route
              path="/muokattavat"
              element={
                <PrivateRoute>
                  <PublicBooksContainer />
                </PrivateRoute>
              }
            />
            <Route
              path="/muokkaa"
              element={
                <PrivateRoute>
                  <PagesContainer />
                </PrivateRoute>
              }
            />
            <Route
              path="/esikatsele"
              element={
                <PrivateRoute>
                  <PreviewContainer />
                </PrivateRoute>
              }
            />
            <Route
              path="/omat-tiedot"
              element={
                <PrivateRoute>
                  <EditAccountContainer />
                </PrivateRoute>
              }
            />
            <Route
              path="/palaute"
              element={
                <PrivateRoute>
                  <FeedbackContainer />
                </PrivateRoute>
              }
            />
            <Route
              path="/opike/:key"
              element={
                <PrivateOpikeRoute>
                  <OpikeContainer />
                </PrivateOpikeRoute>
              }
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
        {isIE && openInfoModal && (!user || (user && !user.id)) && (
          <ModalContainer
            modalIsOpen={openInfoModal}
            closeModal={() => setOpenInfoModal(false)}
            info
            label="Tietoa Internet Explorer-käyttäjille."
          >
            <div>
              <h2>Näytät käyttävän Internet Explorer-selainta.</h2>
              <p style={{ textAlign: 'left' }}>
                Papuri ei välttämättä toimi oikein Microsoft Internet Explorer
                -selaimella. Microsoft ei enää tue ja kehitä sitä. Suosittelemme
                käyttämään jotain muuta selainta.
              </p>
              <Button
                onClick={() => setOpenInfoModal(false)}
                className="dialogue-button gray-button"
              >
                Sulje
              </Button>
            </div>
          </ModalContainer>
        )}
      </main>
    </>
  )
}

export default App
