import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import propTypes from 'prop-types'
import ToolButton from './ToolButton'
// import kyselyIcon from '../QuestionTool/images/kysely-icon.png'
import './styles.scss'
import {
  isTemporarilyDisabled,
  pageLayoutIsHorizontal,
  pageTypeIsFree,
  pageTypeIsGrid,
  pageTypeIsNotFreeOrGrid,
  pageTypeIsQuestion,
} from '../../utils'

const ToolsSider = ({
  setOpenModal,
  setOpenColorModal,
  addNewTextArea,
  addNewBlock,
  addNewQuestion,
  showGrid,
  setShowGrid,
  setOpenUploadModal,
}) => {
  const currentPageId = useSelector((state) => state.pages.currentPage)
  const currentPage = useSelector((state) => state.pages.pages[currentPageId])
  const user = useSelector((state) => state.user)

  const changeImageOrVideo = (type) => {
    type && type === 'video' ? setOpenUploadModal('video') : setOpenModal(true)
  }
  const showOrHideGrid = () => {
    setShowGrid(!showGrid)
  }

  const addImageToPage = () => {
    if (currentPage.type === 6 && currentPage.images.length === 4) {
      alert('Sivulle mahtuu vain neljä kuvaa. Poista ensin joku kuvista.')
    } else {
      setOpenModal(true)
    }
  }

  const enableQuestionTool = (pageType) => {
    if (pageTypeIsFree(pageType) && !pageLayoutIsHorizontal(pageType)) {
      return true
    } else if (pageTypeIsQuestion(pageType)) {
      return true
    } else {
      return false
    }
  }

  return (
    <div className="sider-tools-container">
      {currentPage && (
        <div className="tools-sider-content">
          <ul className="tool-button-list">
            {!pageTypeIsGrid(currentPage.type) && (
              <>
                <li className="tool-button-list-item">
                  <ToolButton
                    onClick={
                      pageTypeIsNotFreeOrGrid(currentPage.type)
                        ? () => changeImageOrVideo('image')
                        : () => {
                            addImageToPage()
                          }
                    }
                    icon={['far', 'image']}
                    text={
                      pageTypeIsNotFreeOrGrid(currentPage.type) &&
                      currentPage.images.length > 0
                        ? 'Vaihda kuva'
                        : 'Lisää kuva'
                    }
                    role="link"
                  />
                </li>
                <li className="tool-button-list-item">
                  {!user.anonymous ? (
                    <ToolButton
                      onClick={
                        pageTypeIsNotFreeOrGrid(currentPage.type)
                          ? () => changeImageOrVideo('video')
                          : () => {
                              setOpenUploadModal('video')
                            }
                      }
                      icon={['far', 'file-video']}
                      text={
                        currentPage.videos.length > 0
                          ? 'Vaihda video'
                          : 'Lisää video'
                      }
                      role="link"
                    />
                  ) : (
                    <ToolButton
                      icon={['far', 'file-video']}
                      text="Lisää video"
                      inactive
                    />
                  )}
                </li>
              </>
            )}
            {(currentPage.type === 4 ||
              currentPage.type === 5 ||
              currentPage.type === 9 ||
              currentPage.type === 15) && (
              <li className="tool-button-list-item">
                <ToolButton
                  onClick={() => addNewTextArea(null, 0, 0, 0.35, 0.2, 1, 1)}
                  icon="align-left"
                  text="Lisää teksti"
                />
              </li>
            )}
            {!pageTypeIsGrid(currentPage.type) && (
              <li className="tool-button-list-item">
                {!user.anonymous ? (
                  <ToolButton
                    onClick={() => setOpenUploadModal('audio')}
                    icon="volume-up"
                    text="Lisää ääni"
                    role="link"
                  />
                ) : (
                  <ToolButton icon="volume-up" text="Lisää ääni" inactive />
                )}
              </li>
            )}
            {!isTemporarilyDisabled() && pageTypeIsFree(currentPage.type) && (
              <>
                <li className="tool-button-list-item">
                  <ToolButton
                    onClick={() => addNewBlock(null, 0, 0, 0.35, 0.2, 1, 1)}
                    icon={['far', 'plus-square']}
                    text="Lisää blokki"
                  />
                </li>
              </>
            )}
            {enableQuestionTool(currentPage.type) && (
              <>
                <li className="tool-button-list-item">
                  <ToolButton
                    onClick={() => addNewQuestion(null, 0, 0, 0.35, 0.2, 1, 1)}
                    icon="question"
                    text="Lisää kysely"
                  />
                </li>
              </>
            )}
            <li className="tool-button-list-item">
              <ToolButton
                onClick={() => setOpenColorModal(true)}
                icon="palette"
                text="Taustaväri"
                role="link"
              />
            </li>
            {(currentPage.type === 4 ||
              currentPage.type === 5 ||
              currentPage.type === 9 ||
              currentPage.type === 15) && (
              <>
                <li className="tool-button-list-item">
                  <ToolButton
                    onClick={showOrHideGrid}
                    icon="border-all"
                    text={showGrid ? 'Piilota apuviivat' : 'Apuviivat'}
                  />
                </li>
              </>
            )}
          </ul>
        </div>
      )}
    </div>
  )
}

ToolsSider.propTypes = {
  setOpenModal: propTypes.func.isRequired,
  setOpenColorModal: propTypes.func.isRequired,
  addNewTextArea: propTypes.func.isRequired,
  addNewBlock: propTypes.func.isRequired,
  addNewQuestion: propTypes.func.isRequired,
  showGrid: propTypes.bool,
  setShowGrid: propTypes.func,
  setOpenUploadModal: propTypes.func,
  removeVideo: propTypes.func,
}

ToolsSider.defaultProps = {
  showGrid: null,
  setShowGrid: null,
  removeVideo: null,
  setOpenUploadModal: null,
}

export default memo(ToolsSider)
