import React, { memo, useEffect, useRef } from 'react'
import Quill from 'quill'
import propTypes from 'prop-types'
import { removeEmptyHtmlTags } from '../../../utils'

const PreviewText = ({ box, parent }) => {
  const editorRef = useRef(null)

  const modifyHtmlForQuill = (html) => {
    // Korvaa <small> elementit <span class="ql-size-small"> elementeillä
    // Tämä auttaa vanhemmilla editoreilla tehtyjä kirjoja näyttämään samalta uudella editorilla
    const withSmallReplaced = html
      .replace(/<small>/g, '<span class="ql-size-small">')
      .replace(/<\/small>/g, '</span>')

    // Korvaa <span class="text-smaller"> elementit <span class="ql-size-small"> elementeillä
    // Tämä auttaa vanhemmilla editoreilla tehtyjä kirjoja näyttämään samalta uudella editorilla
    const withTextSmallerReplaced = withSmallReplaced
      .replace(/<span class="text-smaller">/g, '<span class="ql-size-small">')
      .replace(/<\/span>/g, '</span>')

    // Korvaa <span class="text-larger"> elementit <span class="ql-size-large"> elementeillä
    // Tämä auttaa vanhemmilla editoreilla tehtyjä kirjoja näyttämään samalta uudella editorilla
    const withTextLargerReplaced = withTextSmallerReplaced
      .replace(/<span class="text-larger">/g, '<span class="ql-size-large">')
      .replace(/<\/span>/g, '</span>')

    // Korvaa <span class="large-text"> elementit <span class="ql-size-huge"> elementeillä
    // Tämä auttaa vanhemmilla editoreilla tehtyjä kirjoja näyttämään samalta uudella editorilla
    const withTextLargeReplaced = withTextLargerReplaced
      .replace(/<span class="large-text">/g, '<span class="ql-size-huge">')
      .replace(/<\/span>/g, '</span>')

    return withTextLargeReplaced
  }

  useEffect(() => {
    if (editorRef.current) {
      const quill = new Quill(editorRef.current, {
        theme: 'snow',
        readOnly: true,
        modules: {
          toolbar: false,
        },
      })

      if (box && box.html) {
        const modifiedHtml = modifyHtmlForQuill(box.html)
        quill.clipboard.dangerouslyPasteHTML(removeEmptyHtmlTags(modifiedHtml))
      }
    }
  }, [box])

  const getFontSize = () => {
    if (parent) {
      return `${(parent.width / 1280) * 20 * 1.2}px`
    }
  }

  return (
    <div
      className="preview-editor textbox"
      style={{
        fontSize: getFontSize(),
      }}
    >
      {box && box.html && (
        <div
          id={`editor-${box.id}`}
          ref={editorRef}
          className="quill-editor-custom-class"
        ></div>
      )}
    </div>
  )
}

PreviewText.propTypes = {
  box: propTypes.instanceOf(Object).isRequired,
  parent: propTypes.instanceOf(Object).isRequired,
}

export default memo(PreviewText)
